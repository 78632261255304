import React from "react";
import SVGButton from "../components/svgButton";
import classNames from "classnames";

export default function Home() {
  return (
    <div className="home__content app__section" id="home">
      <div className="home__container">
        <div className="home__welcome">Welcome! My name is </div>
        <div className="home__name">Byron Peterson</div>
        <div className="home__description">
          I build beautiful, fast and responsive websites while providing
          excellent service and value to my clients
        </div>
        <div className="home__button-container">
          <div className="home__work-button">
            <SVGButton
              text="See My Work"
              linkedElement={"work"}
              svgColorClass={"svgColorMain"}
            />
          </div>
          <div className="home__contact-button">
            <SVGButton
              text="Talk to Me"
              linkedElement={"contact"}
              svgColorClass={"svgColorSecondary"}
            />
          </div>
        </div>
      </div>
      {/* <div className="home_filler"></div> */}
    </div>
  );
}
