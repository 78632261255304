import React, { useState, useEffect } from "react";
import clientData from "../data/clients.json";
import ClientCard from "../components/clientCard";

export default function Work() {
  const test = "https://audiobymarkjones.ca/";
  const [currentClient, setCurrentClient] = useState(1);
  let theCurrentClient = null;
  let newIndex = 2;

  const newClientData = clientData.map((client, index) => {
    if (client.URL === test) {
      client.id = 1;
    } else {
      client.id = newIndex;
      newIndex += 1;
    }
    return client;
  });
  newClientData.sort(function (a, b) {
    return a.id - b.id;
  });
  const getTheCurrentClient = () => {
    let current = clientData.filter((client) => client.id === currentClient)[0];
    return current;
  };
  const setPrevious = () => {
    const dataLength = clientData.length;
    let el = document.getElementById("client-card-container");
    el.classList.add("work__client-card-container-hide");
    setTimeout(() => {
      if (currentClient > 1) setCurrentClient(currentClient - 1);
      else setCurrentClient(dataLength);
      el.classList.remove("work__client-card-container-hide");
    }, 200);
  };
  const setNext = () => {
    const dataLength = clientData.length;
    let el = document.getElementById("client-card-container");
    el.classList.add("work__client-card-container-hide");
    setTimeout(() => {
      if (currentClient < dataLength) setCurrentClient(currentClient + 1);
      else setCurrentClient(1);
      el.classList.remove("work__client-card-container-hide");
    }, 200);
  };
  const setCurrent = (event) => {
    // const allClients = document.getElementsByClassName("work__client");
    // Array.prototype.forEach.call(allClients, (client) =>
    //   client.classList.remove("work__client-current")
    // );
    // //   .classList.remove("work__client-current");
    document
      .getElementById(currentClient)
      .classList.remove("work__client-current");
    document
      .getElementById(event.target.id)
      .classList.add("work__client-current");
    if (parseInt(event.target.id) !== currentClient) {
      let el = document.getElementById("client-card-container");
      el.classList.add("work__client-card-container-hide");
      setTimeout(() => {
        setCurrentClient(parseInt(event.target.id));
        el.classList.remove("work__client-card-container-hide");
      }, 500);
    }
  };

  return (
    <div id="work" className="app__section work__content">
      <div className="work__container">
        <div className="container__top">
          Below are some of my recent clients
        </div>
        <div className="container__bottom">
          <div className="work__client-list">
            {newClientData.map((client) => {
              return (
                <div
                  className="work__client"
                  key={client.id}
                  onClick={setCurrent}
                  id={client.id}
                >
                  {client.name}
                </div>
              );
            })}
          </div>
          <div
            className="work__client-card-container"
            id="client-card-container"
          >
            <div className="arrow left-arrow">
              <span className="material-icons-outlined" onClick={setPrevious}>
                arrow_left
              </span>
            </div>
            {/* <div>
              <img
                src={"images/final/laptop-and-phone-MJA.jpg"}
                style={{ objectFit: "contain" }}
              />
            </div> */}
            <ClientCard
              client={getTheCurrentClient()}
              key={currentClient}
            ></ClientCard>
            <div className="arrow right-arrow">
              <span className="material-icons-outlined" onClick={setNext}>
                arrow_right
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
