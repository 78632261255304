import React from "react";
import SVGButton from "../components/svgButton";
import Social from "../components/social";
export default function About() {
  return (
    <div id="about" className="app__section about__content">
      <div className="about__container about__left-container">
        <span className="text text-1">
          I've been a web developer and designer for around 20 years now, mostly
          working in the corporate world.
        </span>
        <span className="text text-2">
          Throughout my career, I've worked with Canadian banks, insurance
          companies, the auto industry and the health sector, just to name a
          few. More recently I've started working on my own, helping small
          businesses get their message out on the web.
        </span>
        <span className="text text-3">
          I pride myself on my ability to assess and solve problems in a quick
          and efficient manner. I do not believe in cookie-cutter approaches, or
          one-size-fits-all solutions. Each of my clients has different needs,
          which sometimes require creative thinking.
        </span>
        <span className="text text-4">
          That being said, you can rest easy, knowing that I can provide
          solutions utilizing a wide variety of technologies to meet your
          individual needs.
        </span>
        <span className="about__cta"></span>
      </div>
      <div className="about__container about__right-container">
        <SVGButton
          text="Talk to me"
          linkedElement={"contact"}
          svgColorClass={"svgColorMain"}
        />
      </div>
      <Social></Social>
    </div>
  );
}
