import React from "react";
// import "../css/social.scss";
import Facebook from "../images/social/Facebook";
import Instagram from "../images/social/Instagram";
import LinkedIn from "../images/social/Linkedin";
export default function Social() {
  const fillColor = "#ffffff";
  return (
    <div className="social">
      <Facebook fill={fillColor}></Facebook>
      <Instagram fill={fillColor}></Instagram>
      <LinkedIn fill={fillColor}></LinkedIn>
    </div>
  );
}
