import React from "react";

export default function Contact() {
  return (
    <div id="contact" className="app__section contact__content">
      <div className="contact__heading">
        <span>
          Please contact me via email or telephone to discuss your needs.
        </span>
      </div>
      <div className="contact__info">
        <div>
          <label>Email:</label>
          <a
            href="mailto:byron@byroncpeterson.com"
            target="_blank"
            rel="noreferrer"
          >
            byron@byroncpeterson.com
          </a>
        </div>
        <div>
          <label>Phone:</label>
          <a href="tel:6474026823">647-402-6823</a>
        </div>
      </div>
    </div>
  );
}
