import React from "react";

export default function clientCard(props) {
  const client = props.client;
  const showInfo = () => {
    const el = document.getElementById("client" + client.id);
    el.classList.add("client__info-show");
    // console.log(el);
  };
  const hideInfo = () => {
    // console.log("info");
    const el = document.getElementById("client" + client.id);
    el.classList.remove("client__info-show");
    // console.log(el);
  };
  return (
    <div
      className="client__container"
      onMouseEnter={showInfo}
      onMouseLeave={hideInfo}
    >
      <div className="client__hero">
        <img src={"images/" + client.image.src} />
      </div>
      <div className="client__info" id={"client" + client.id}>
        <div className="client__text">{client.text}</div>
        {client.testimonial.text.length > 0 && (
          <div className="client__testimonial" id={"client" + client.id}>
            <span className="client__testimonial-text">
              {client.testimonial.text}
            </span>{" "}
            -{" "}
            <span className="client__testimonial-name">
              {client.testimonial.name}
            </span>
          </div>
        )}
        <div className="client__link">
          <a href={client.URL} target="_blank">
            Visit site
          </a>
        </div>
      </div>
    </div>
  );
}
