import React from "react";
import classNames from "classnames";

export default function svgButton(props) {
  const scrollElement = () => {
    const elementToScrollTo = document.getElementById(props.linkedElement);
    elementToScrollTo.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={("svgButtonContainer", props.svgColorClass)}>
      <a className="cta" onClick={scrollElement}>
        <span>{props.text}</span>
        <svg width="13px" height="10px" viewBox="0 0 13 10"></svg>
      </a>
    </div>
  );
}
