import { useState } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Home from "./pages/Home";
import Header from "./components/header";
import About from "./pages/About";
import Work from "./pages/Work";
import Contact from "./pages/Contact";

function App() {
  const [currentPage, setCurrentPage] = useState("home");
  const navigate = (dest) => {
    const elementToScrollTo = document.getElementById(dest);
    elementToScrollTo.scrollIntoView({ behavior: "smooth" });
    setCurrentPage(dest);
  };
  return (
    <div className="App">
      <Header
        className="App-header"
        nav={navigate}
        current={currentPage}
      ></Header>
      <Home></Home>
      <About></About>
      <Work></Work>
      <Contact></Contact>
    </div>
  );
}

export default App;
