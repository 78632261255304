import React, { useEffect } from "react";
import logo from "../images/logo.svg";
import classNames from "classnames";
import selectByClass from "../utils/selector";

export default function Header(props) {
  useEffect(() => {
    const sections = [
      selectByClass("home__content"),
      selectByClass("about__content"),
      selectByClass("work__content"),
      selectByClass("contact__content"),
    ];

    const navItems = {
      home: selectByClass("homeNavItem"),
      about: selectByClass("aboutNavItem"),
      work: selectByClass("workNavItem"),
      contact: selectByClass("contactNavItem"),
    };

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.7,
    };

    function observerCallback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // get the nav item corresponding to the id of the section
          // that is currently in view
          const navItem = navItems[entry.target.id];
          // add 'active' class on the navItem
          navItem.classList.add("header__active");
          // remove 'active' class from any navItem that is not
          // same as 'navItem' defined above
          Object.values(navItems).forEach((item) => {
            if (item != navItem) {
              item.classList.remove("header__active");
            }
          });
        }
      });
    }

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    sections.forEach((sec) => observer.observe(sec));
  }, []);
  return (
    <nav>
      <div className="header__logo">
        <img
          src={logo}
          className="header__logo"
          onClick={() => props.nav("home")}
        />
      </div>
      <div className="header__menu">
        <span
          className={classNames(
            "homeNavItem",
            props.current === "home" ? "header__active" : ""
          )}
          onClick={() => props.nav("home")}
        >
          Home
        </span>
        <span
          className={classNames(
            "aboutNavItem",
            props.current === "about" ? "header__active" : ""
          )}
          onClick={() => props.nav("about")}
        >
          About
        </span>
        <span
          className={classNames(
            "workNavItem",
            props.current === "work" ? "header__active" : ""
          )}
          onClick={() => props.nav("work")}
        >
          Work
        </span>
        <span
          className={classNames(
            "contactNavItem",
            props.current === "contact" ? "header__active" : ""
          )}
          onClick={() => props.nav("contact")}
        >
          Contact
        </span>
      </div>
      {/* <div className="underline-container">
        <div className="top-underline"></div>
        <div className="bottom-underline"></div>
      </div> */}
    </nav>
  );
}
